<template>
    <div class="book-expert-ins-page">
        <!-- 文集集刊学者机构 -->
        <!-- <collection-detail></collection-detail> -->
        <collected-papers v-if="isCollection"></collected-papers>
        <institution v-if="isIns"></institution>
        <expert v-if="isExpert"></expert>

    </div>
</template>

<script>
import CollectionDetail from './CollectionDetail'
import Expert from './Expert'
import Institution from './Institution'
import CollectedPapers from './CollectedPapers'

export default {
    components: { CollectionDetail, Expert, Institution, CollectedPapers },
    data () {
        return {
            isCollection: false,
            isExpert: false,
            isIns: false
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init () {
            sessionStorage.clear()
            this.isType()
        },
        isChinese(temp) {
          var re = /[^\u4E00-\u9FA5]/;
          if (re.test(temp)) return false;
          return true;
        },
        isType () {
            var _this = this
            var name = this.$route.params.id
            if (!this.isChinese(name)){
                 name = decodeURIComponent(name);
            }
            console.log(name)
            this.$http({
                methods: 'get',
                url: '/admin/api/index/resourceType?name=' + encodeURI(name)
            }).then(res => {
                var data = res.data

                if (data.resourceType == 'COLLECTED_PAPERS') {
                    //集刊
                    // data.id
                    _this.isCollection = true
                    _this.isExpert = false
                    _this.isIns = false
                    sessionStorage.setItem('idCollection', data.id)
                } else if (data.resourceType == 'RESEARCH_ARCHIVES') {
                    //机构
                    _this.isCollection = false
                    _this.isExpert = false
                    _this.isIns = true
                    sessionStorage.setItem('idIns', data.id)
                } else if (data.resourceType == 'SCHOLAR_ARCHIVES') {
                    //学者
                    _this.isCollection = false
                    _this.isExpert = true
                    _this.isIns = false
                    sessionStorage.setItem('idExpert', data.id)
                }
            })
        }
    },
    created () {
        this.init()
        // console.log('$route', this.$route.params.id)
    },
    destroyed () {
        sessionStorage.clear()
    }
}
</script>

<style lang="scss" scoped>
.book-expert-ins-page {

}
</style>