<template>
    <div class="wid1200 institution-page">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <div class="institution-wrap">
            <div class="institution">
                <div class="institution-img">
                    <img v-if="insObj.picture" :src="insObj.picture" alt="" />
                    <img v-else alt="" />
                    <!-- <img src="../assets/img/book.png" alt="" /> -->
                </div>
                <div class="institution-info">
                    <h5><span>{{insObj.name}}</span></h5>
                    <div class="content-institution">
                        {{insObj.description}}
                    </div>
                </div>
            </div>
            <ul class="institution-chart">
                <li>
                    <div class="img-div">`
                        <img src="../assets/img/article1.png" alt="" />
                    </div>
                    <div class="content-div">
                        <h5><span>{{articleData.total}}</span> 篇</h5>
                        <h6>机构文章</h6>
                    </div>
                </li>
                <li>
                    <div class="img-div">`
                        <img src="../assets/img/ins2.png" alt="" />
                    </div>
                    <div class="content-div">
                        <h5><span>{{bookData.total}}</span> 篇</h5>
                        <h6>机构著作</h6>
                    </div>

                </li>
            </ul>
        </div>
        <div class="expert-wrap">
            <div class="com-title">
                <div>
                    <h5>机构学者</h5>
                </div>
                <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
            </div>
            <div class="expert">
                <expert-swiper :areaResearchData="expertData" :page-name="pageName"></expert-swiper>
            </div>
        </div>
        <div class="article-wrap" v-if="articleData && articleData.total>0">
            <com-article :articleData="articleData" :page-name="pageName" @pageChange="articleChange"/>
        </div>
        <div class="book-wrap" v-if="bookData && bookData.total>0">
            <com-book :bookData="bookData" :page-name="pageName" @pageChange="bookChange" />
        </div>
        <div class="relevant-ins-wrap com-relevant">
            <div class="com-title">
                <div>
                    <img class="title-img" src="../assets/img/insicon.png" alt="" />
                    <h5>相关机构</h5>
                </div>
                <!-- <div>
                    <span>更多<img src="../assets/img/more.png" alt="" /></span>
                </div> -->
            </div>
            <div class="relevant-ins">
                <div class="relevant-ins-img">
                    <img v-if="insData[0]" :src="insData[0].picture" alt="" />
                    <img v-else alt="" />
                </div>
                <div class="relevant-ins-info">
                    <!-- researchName  relateName -->
                    <h5 @click="goIns(insData[0].relateId)" v-if="insData[0]">{{insData[0].researchName}}</h5>
                    <p v-if="insData && insData[0] && insData[0].description">
                        
                        {{insData[0].description}}
                    </p>
                </div>
            </div>
            <ul>
                <li v-for="(item, ind) in insData" :key="item.id" v-if="ind>0" @click="goIns(item.relateId)">
                    {{item.researchName}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import ComArticle from '../components/ComArticle'
import ComBook from '../components/ComBook'
import ExpertSwiper from '../components/ExpertSwiper'

import img1 from '../assets/img/book.png'
import im1 from '../assets/img/book.png'

export default {
    components: { BreadCrumb, ComArticle, ComBook, ExpertSwiper },
    data() {
        return {
            pN: '',
            pageName: '机构学术主页',
            insObj: {},
            expertData: [{}],
            insData: [], //相关机构

            articleData: {
                title: '机构文章',
                total: 0,
                articleList: [
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    }
                ]
            },
            bookData: {
                title: '机构图书',
                total: 0,
                bookList: [
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    }
                ]
            },
            pageNum: 1,
            pageSize: 10

        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        goIns(id) {
            this.$router.push({ path: '/ins/i', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        init() {
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }
            this.getDetail()
            this.getList('jgwz') //机构文章
            this.getList('jgzz') //机构著作
        },
        articleChange (val) {
            this.pageNum = val
            this.getList('jgwz') //机构文章
        },
        bookChange (val) {
            this.pageNum = val
            this.getList('jgzz') //机构著作
        },
        getDetail() {
            var _this = this
            var curId
            if (this.$route.query.id) {
                curId = this.$route.query.id
            } else {
                curId = sessionStorage.getItem('idIns')
            }

            this.$http({
                method: 'get',
                url: '/admin/api/research/archives?id=' + curId
            }).then(res => {
                if (res.data.code == 0) {
                    var data = res.data.data
                    _this.pageName = data.name
                    _this.insObj = data
                    _this.expertData = data.scholarList
                    _this.insData = data.researchList
                }
            })
        },
        getList(type) {
            var _this = this
            if (type == 'jgwz') {
                this.pageSize = 14
            } else if (type == 'jgzz') {
                this.pageSize = 10
            }

            var curId
            if (this.$route.query.id) {
                curId = this.$route.query.id
            } else {
                curId = sessionStorage.getItem('idIns')
            }


            this.$http({
                method: 'get',
                url: '/admin/api/research/list',
                params: {
                    id: curId,
                    subject: type,
                    year: '', //年份
                    sort: 'asc', //asc、desc
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                }
            }).then(res => {
                if (res.data.code == 0) {
                    if (type == 'jgwz') {
                        _this.articleData.articleList = res.data.data.rows
                        _this.articleData.total = res.data.data.total
                        
                    } else if (type == 'jgzz') {
                        _this.bookData.bookList = res.data.data.rows
                        _this.bookData.total = res.data.data.total
                    }
                }
            })
        }
    },
    created() {
        this.init()

        //         机构详情：			/api/research/archives		id
        // 机构资源列表：			/api/research/list			id 			机构id
        // 								subject		栏目（机构文章：jgwz  机构著作：jgzz）
        // 								year		年份
        // 								sort 		排序（asc，desc）
        // 机构资源详情：			/api/research/detail			resourceId	资源id
    }
}
</script>

<style lang="scss" scoped>
.institution-page {
    .institution-wrap {
        padding: 10px 30px 0;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        .institution {
            display: flex;
            margin-top: 21px;
            .institution-img {
                margin-right: 30px;
                margin-bottom: 25px;
                img {
                    width: 145px;
                    min-height: 188px;
                    box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                }
            }
            .institution-info {
                h5 {
                    margin-bottom: 18px;
                    span {
                        font-size: 22px;
                        line-height: 22px;
                        padding-bottom: 2px;
                        border-bottom: 3px solid #bd1a2d;
                        cursor: pointer;
                    }
                }
                .content-institution {
                    color: #666;
                    line-height: 28px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5; // 控制多行的行数
                    -webkit-box-orient: vertical;
                }
            }
        }
        .institution-chart {
            width: 1138px;
            height: 140px;
            border: 1px solid #e6e6e6;
            margin-bottom: 30px;
            background: url(../assets/img/databg.jpg);
            background-size: 100% 100%;
            background-position: 0 0;
            display: flex;
            li {
                display: flex;
                width: 569px;
                padding-left: 154px;
                .img-div {
                    margin-top: 28px;
                    margin-right: 19px;
                    img {
                        width: 79px;
                        height: 79px;
                    }
                }
                .content-div {
                    h5 {
                        color: #fff;
                        font-size: 24px;
                        margin-top: 12px;
                        span {
                            color: #fff;
                            font-size: 56px;
                        }
                    }
                    h6 {
                        font-size: 17px;
                        color: #fff;
                        // margin-left: 33px;
                    }
                }
            }
        }
    }
    .expert-wrap {
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 10px 30px 0;
        height: 361px;
        margin-top: 25px;
    }
    .article-wrap {
        padding: 10px 30px 0;
        margin: 26px auto;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .book-wrap {
        padding: 10px 30px 0;
        margin-bottom: 26px;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .relevant-ins-wrap {
        margin-bottom: 30px;

        .relevant-ins {
            padding-top: 14px;
            display: flex;
            // justify-content: space-between;
            .relevant-ins-img {
                margin-right: 13px;
                img {
                    min-width: 106px;
                    height: 142px;

                    cursor: pointer;
                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                }
            }
            .relevant-ins-info {
                h5 {
                    font-size: 18px;
                    line-height: 18px;
                    margin-bottom: 8px;
                    cursor: pointer;
                }
                p {
                    color: #666;
                    line-height: 28px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4; // 控制多行的行数
                    -webkit-box-orient: vertical;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 231px;
                box-sizing: border-box;
                padding-left: 10px;
                position: relative;
                font-size: 18px;
            }
            li::before {
                content: '';
                width: 4px;
                height: 4px;
                position: absolute;
                left: 0;
                top: 12px;
                background: #ffac13;
            }
        }
    }
    .com-relevant {
        width: 1200px;
        height: 303px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 10px 20px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 8px;
            li {
                margin-bottom: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }
}
</style>